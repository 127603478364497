import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import classes from './Navigation.module.css';

function Navigation() {
  let userName: any = sessionStorage.getItem('userName');
  userName = userName.toString();
  userName = userName.slice(0, -16);
  //userName = userName.split(".")
  //let Fname = userName[0];
  //let Lname = userName [1]

  return (
    <header className={classes.header}>
      <div className={classes.logo}>Latam Datazap</div>
      <nav>
        <ul>
          <li>
            <Link to="/">Main</Link>
          </li>
          <li>
            <Link to="/update">Update</Link>
          </li>
          <li>
            <Link to="/history">History</Link>
          </li>
          <li>
            <Link to="/logout">Logout</Link>
          </li>
          <li className={classes.badge}>
            <FaUser /> {userName}
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Navigation;
