function Logout({ setSession }: any) {
  const logout = () => {
    setSession(null);
    //window.location.href = config.redirectSignOut;
  };

  return (
    <div>
      <h2>Logout</h2>
      <p>You are successfully logged out.</p>
      <button onClick={logout}>go back</button>
    </div>
  );
}

export default Logout;
