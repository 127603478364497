import axios from 'axios';
import jwt from 'jsonwebtoken';
import { config } from './config';

// let challenge = localStorage.uniqueUuid;
// let code_verifier = localStorage.uniqueUuid_verifer;

// if(localStorage.uniqueUuid){
//   localStorage.removeItem("uniqueUuid");
//   localStorage.removeItem("uniqueUuid_verifer");
//   sessionStorage.removeItem("userName")
// }else{
//     const pkceChallenge = require("pkce-challenge");
//     const code = pkceChallenge(43);
//     challenge = code.code_challenge;
//     code_verifier = code.code_verifier;
//     localStorage.setItem("uniqueUuid", challenge);
//     localStorage.setItem("uniqueUuid_verifer", code_verifier);
//     console.log ('code_challenge  : ',challenge)
//     console.log ('code_verifier : ',code_verifier)
// }

export const redirectToSso = (challenge: any) => {
  const params = new URLSearchParams();
  params.append('client_id', config.clientId);
  params.append('response_type', 'code');
  params.append('scope', config.scope.join(' '));
  params.append('redirect_uri', config.redirectSignIn);
  params.append('code_challenge', challenge);
  params.append('nonce', 'abcdefghijklmnopqrstuvwxyz');
  params.append('code_challenge_method', 'S256');
  window.location.href = `${config.authorizationEndpoint}?${params.toString()}`;
};

export const loginWithAuthorizationCode = async (code: any) => {
  try {
    const tokenEndpoint = config.tokenEndpoint;
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    //console.log ('code_verifier token : ',code.code_verifier)
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', code.code);
    params.append('client_id', config.clientId);
    params.append('code_verifier', code.code_verifier);
    params.append('redirect_uri', config.redirectSignIn);

    const resp = await axios.post(tokenEndpoint, params, { headers: headers });

    const token = resp.data['id_token'];
    const decoded: any = jwt.decode(token);
    const user = decoded['userPrincipalName'];
    const groups_o = decoded['groups'];
    const groups = groups_o.toString()
    const arrGroups = groups.split(",");
    const ADgroups = config.adminGroup;
    console.log ("arrGroups : ", arrGroups);
    //console.log ("config.adminGroup : ", config.adminGroup);

    const found = arrGroups.some((r:string)=> ADgroups.indexOf(r) >= 0);
    //console.log ("found : ", found);
    if (found) {
      sessionStorage.setItem('userName', user);
      const ADGroup = groups_o.find( (e: string) => e === "HQDOMAIN_Horizon App Support");
      sessionStorage.setItem('ADgroup', ADGroup);
      console.log("ADgroup: ",ADGroup);
      return { user: user, group: ADGroup, token: token };
    } else {
      throw new Error(`You must be a member of ${config.adminGroup} to use this app`);
    }

  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw err;
  }
};

export const logout = () => {
  window.location.href = config.redirectSignOut;
};
