import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

export function LinearDeterminate() {
    const [progress, setProgress] = useState(10);

    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }, []);

    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    );
}

export function ScriptAlert(props: any) {
  const responsealert = props.data;
  return (
    <>
      <Alert severity="error" color="error">
        {responsealert}
      </Alert>
    </>
  );
}

export function ScriptInfo(props: any) {
  const git_folder = props.git_folder;
  const folderName = props.folderName;
  const handleClick = () => {
    window.open(`${git_folder}/${folderName}`);
  };

  return (
    <>
      <Alert severity="success" color="info">
        SQL script successfully loaded in{' '}
        <Link onClick={handleClick}>
          {git_folder}/{folderName}
        </Link>
        {'\n'}You may check the JIRA Github Workflow in # hzn-latam-datazap Slack channel
      </Alert>
    </>
  );
}