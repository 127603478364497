import { makeStyles } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import Navigation from './Navigation';
import Toolbar from '@mui/material/Toolbar';

const useStyles = makeStyles((theme): any => {
  return {
    page: {
      background: '#f9f9f9',
      width: '100%'
    },
    root: {
      display: 'flex'
    },
    toolbar: theme.mixins.toolbar,
    navigation: {
      flexGrow: 1
    },
    avatar: {
      marginLeft: theme.spacing(2),
      background: '#8d20cc',
      borderRadius: '12px',
      padding: '0 1rem',
      flexGrow: 1
    }
  };
});

function Layout({ children }:any) {
  const classes: any = useStyles();

  return(
    <div className={classes.root}>
      <AppBar elevation={0} sx={{ backgroundColor: '#0f0f0f', color: 'white' }}>
        <Toolbar>
          <Navigation />
        </Toolbar>
      </AppBar>
      <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {children}
      </div>
    </div>
  );
}

export default Layout;
