import { loginWithAuthorizationCode, redirectToSso } from './authentication';
import { useEffect } from 'react';
import pkceChallenge from 'pkce-challenge';

function Login({ setSession }: any) {
  useEffect(() => {
    const token_auth = async () => {
      let challenge = localStorage.uniqueUuid;
      let code_verifier = localStorage.uniqueUuid_verifer;

      if (localStorage.uniqueUuid) {
        localStorage.removeItem('uniqueUuid');
        localStorage.removeItem('uniqueUuid_verifer');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('ADgroup');
      } else {
        const code = pkceChallenge(43);
        challenge = code.code_challenge;
        code_verifier = code.code_verifier;
        localStorage.setItem('uniqueUuid', challenge);
        localStorage.setItem('uniqueUuid_verifer', code_verifier);
        //console.log('code_challenge  : ', challenge);
        //console.log('code_verifier : ', code_verifier);
      }
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const code = params.get('code');
      //console.log('url: ', url)
      //console.log('login code: ',code)
      if (code) {
        console.log('Logging in with authentication code...');
        try {
          const session = await loginWithAuthorizationCode({ code, code_verifier });
          setSession(session);
        } catch (err: any) {
          console.error(err);
        }
      } else {
        redirectToSso(challenge);
      }
    };
    token_auth();
  }, [setSession]);

  return null;
}

export default Login;
