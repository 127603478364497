import { config, Card, LinearDeterminate, ScriptAlert} from '../load';
import axios from 'axios';
import { useState } from 'react';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const URL = `${config.lambdaURL}select`;
const req_header = config.req_header

export function Decrypt() {

    const [input, setInput] = useState('');
    const [decryptedmdn, setDecryptedMDN] = useState('');
    const [isprocessing, setIsprocessing] = useState(false);
    const [responsealert, setResponsealert] = useState('');

    function DecryptInput(e: any) {
        e.preventDefault();
        setIsprocessing(true);
        axios
          .get(`${URL}?action=decrypt&  =${input}`, { headers: req_header })
          .then((response: any) => {
            if (Object.keys(response.data.message).length === 0) {
              alert('ERROR: Response message is empty. Please check cloudwatch logs');
              setResponsealert('ERROR: Response message is empty. Please check cloudwatch logs');
            } else {
              if (JSON.stringify(response.data.message).includes('ERROR:')) {
                alert('ERROR: ' + JSON.stringify(response.data.message));
                setResponsealert(JSON.stringify(response.data.message));
              } else {
                setDecryptedMDN(response.data.message);
              }
            }
            setIsprocessing(false);
          })
          .catch((err) => {
            console.log(err);
            alert(err.message);
          });
      }

    return(
    <Box component="form" sx={{ maxWidth: '100%', '& .MuiTextField-root': { m: 1 } }} >
        {isprocessing && <LinearDeterminate />}
        {responsealert && <ScriptAlert data={responsealert}/>}
        <Card>
          <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>DECRYPT</Typography>
              <TextField label="Input" size="small" margin="normal"
                placeholder="Enter value for decrypt"
                value={input}
                onChange={(e) => {setInput(e.target.value);}}
              />
              <Button type="submit" onClick={DecryptInput} variant="contained">decrypt</Button>
              <br/>
              <TextField label="Decrypted value" size="small" margin="normal"
                value={decryptedmdn}
              />
          </CardContent>
        </Card>
    </Box>
    );
}