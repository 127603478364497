const config = {
  clientId: 'LatamDatazapDev',
  authorizationEndpoint: 'https://ndcsso.asurion.com:9031/as/authorization.oauth2',
  tokenEndpoint: 'https://ndcsso.asurion.com:9031/as/token.oauth2',
  redirectSignIn: 'https://latam.feat.mobnpr.asurion53.com/',
  redirectSignOut: 'https://latam.feat.mobnpr.asurion53.com/',
  responseType: 'code',
  scope: ['openid', 'profile', 'email'],
  adminGroup: ['HQDOMAIN_Horizon App Support', 'HQDOMAIN_APP-PR-ALL-WB-LATAM-ANALYTICS-OFFSHORE'],
  gitrepo: 'npr',
  lambdaURL: 'https://1wmbbeeasc.execute-api.us-east-1.amazonaws.com/prod/',
  req_header: { 'Content-Type': 'application/json','x-api-key': 'R9bUrzOhxcfhsABSDIJBF^sOJ=rof^yi'}
};

module.exports = { config };
