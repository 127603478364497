import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';
import Main from './pages/Main';
import UpdatePage from './pages/UpdatePage';
import HistoryPage from './pages/History';
import Layout from './component/layout/Layout';
import Logout from './pages/Logout';
import { useState } from 'react';
import Login from './Login';

const theme = createTheme({
  palette: {
    primary: { main: '#8223D2' },
    secondary: indigo,
    // asurion_green: #6EFAC3
    // asurion_blue: #6B8BFF
    // asurion_yellow: #D2FA46
    // asurion_dark_grey: #333F48
  }
});

function App() {
  const [session, setSession]: any = useState(null);

  if (session) {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route path="/" exact={true}>
                <Main />
              </Route>
              <Route path="/update">
                <UpdatePage />
              </Route>
              <Route path="/history">
                <HistoryPage />
              </Route>
              <Route path="/logout">
                <Logout setSession={setSession} />
              </Route>
            </Switch>
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    );
  } else {
    return <Login setSession={setSession} />;
  }
}

export default App;
